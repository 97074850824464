<script lang="ts">
    import { createEventDispatcher, onMount } from 'svelte';
    import { OverlayScrollbars } from 'overlayscrollbars';
    import PrivacyPolicy from '$lib/assets/term/PrivacyPolicy.pdf';

    import { Button, ImagePlaceholder, Divider } from '@allibee/components/basic';

    import { PDFViewer } from '@allibee/components/common';
    import { _ } from 'svelte-i18n';

    const dispatch = createEventDispatcher();

    let scrollRef: HTMLDivElement;

    onMount(() => {
        if (!scrollRef) return;
        const osInstanceScrollArea = OverlayScrollbars(scrollRef, {
            scrollbars: {
                autoHide: 'scroll',
            },
        });
        return () => {
            osInstanceScrollArea.destroy();
        };
    });
</script>

<section class="flex max-h-[50rem] w-full flex-col text-center">
    <header class="p-6 text-left text-lg font-semibold text-gray-900">{$_('apps.contract.lib.service.term.ModalPrivacyPolicy.modalTitle')}</header>
    <Divider />

    <div class="h-full p-6 text-center">
        {#if PrivacyPolicy}
            <div bind:this={scrollRef} class="h-[40rem] gap-6 rounded-lg border-2 border-gray-200 text-center" on:wheel|stopPropagation|passive>
                <PDFViewer contract={PrivacyPolicy} />
            </div>
        {:else}
            <ImagePlaceholder imgHeight="[40rem]" />
        {/if}
    </div>
    <Divider />
    <footer class="flex max-w-4xl justify-end rounded-b-lg bg-white p-6">
        <Button on:click={() => dispatch('confirm', true)}>{$_('lib.service.term.modalFontLicense.actionConfirm')}</Button>
    </footer>
</section>
